import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/layout';
import PropTypes from 'prop-types';
import { CommonContext } from '../../context';

export const query = graphql`
  query {
    allMessages {
      nodes {
        donation {
          success
        }
      }
    }
  }
`;
export default function DonationSuccess({ data, location }) {
  const message = data.allMessages.nodes[0].donation.success;
  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout title="Donation Successful" location={location}>
        <div className="flex justify-center px-6 py-6 md:py-16">
          <div className="w-full max-w-3xl pt-20 shadow-2xl rounded-2xl">
            <img className="mx-auto" src="/images/undraw_completed_ngx6.svg" />
            <div
              className="max-w-screen-sm m-auto mt-6 mb-6 leading-6 text-center md:mt-8"
              dangerouslySetInnerHTML={{
                __html: message.replace('\n', '<br>'),
              }}
            ></div>
            <div className="mb-12 text-sm leading-6 text-center font-semiBold text-primary md:mb-52 md:text-base">
              <Link to="/">Go Back to homepage</Link>
            </div>
          </div>
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

DonationSuccess.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};
